import React from "react";

import Layout from "../components/layout/layout";
import HomePage from "../components/home/home";
import Seo from "../components/seo";

const Home = () => (
	<>
		<Seo
			title="Mereos sécurise vos évaluations à distance"
			description="Mereos sécurise vos examens à distance et certifie le niveau des candidats. Planifiez jusqu'à 20 000 sessions surveillées en simultané et simplifiez votre processus de recrutement."
		/>
		<Layout>
			<HomePage />
		</Layout>
	</>
);

export default Home
